import React, { useEffect, useRef, useState } from "react"
import styles from "./styles/home.module.css"
import "mind-ar/dist/mindar-image.prod.js"
import "mind-ar/dist/mindar-image-three.prod.js"
import { PlaneGeometry, MeshBasicMaterial, Mesh, VideoTexture } from "three"
import { TextureLoader } from "three/src/loaders/TextureLoader"
import LogoImage from "./assets/LOGO_white.png"
import { CompileFile, CompileReturn, LoadImageReturn } from "./compile"
import { AppButton, LoadingPreview, LoadingAssetsView, MainMenuContainer, TopAppButton, MapButton } from "./components"
import { useParams } from "react-router-dom"
import { LoadPosterIDFromSticker } from "./retreiver"
import { retrieveNearbyPosters } from "./map"

type RouteParams = {
  stickerID: string
  posterID: string
}

const MindARThree = (window as any).MINDAR.IMAGE.MindARThree
export const maxDistance = 10
export const Main: React.FC<{}> = (): JSX.Element => {
  const sceneRef = useRef<HTMLDivElement>(null)
  const [status, setStatus] = useState("Loading")
  const [progress, setProgress] = useState(0)
  const [focusedPoster, setFocusedPoster] = useState<LoadImageReturn>()
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)

  const [compilation, setCompilation] = useState<CompileReturn>()
  const { stickerID, posterID } = useParams<RouteParams>()
  const [imageUrl, setImageUrl] = useState<string>("")

  useEffect(() => {
    if (sceneRef.current === null) {
      return
    }

    if (stickerID === undefined && posterID === undefined) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          setStatus("Loading Revivarts near you")

          retrieveNearbyPosters(maxDistance, userLocation).then((coords) => {
            const list =
              coords?.map((val) => {
                return val.uid
              }) ?? []
            setProgress(0.1)
            RunCompile(list)
          })
        })
      }
    } else if (stickerID !== undefined) {
      LoadPosterIDFromSticker(stickerID).then((target) => {
        RunCompile([target])
      })
    } else if (posterID !== undefined) {
      RunCompile([posterID])
    }

    function RunCompile(uids: string[]) {
      // let _start = new Date().getTime()
      CompileFile(uids, (progress) => {
        setProgress(progress)
        setStatus("Loading Preview:  " + progress.toFixed(2) + "%")
      }).then((target) => {
        if (target.posters.length === 1) {
          setImageUrl(target.posters[0].img.src)
        }

        setCompilation(target)
        setStatus("Point you camera to the image (Revivart). \n Make sure you camera is enabled")
        const mindarThree = new MindARThree({
          container: document.querySelector("#container"),
          imageTargetSrc: target.compiledURl,
          filterMinCF: 1,
          filterBeta: 10000,
          missTolerance: 0,
          warmupTolerance: 0,
          maxTrack: 1,
          uiLoading: "no",
        })

        const { renderer, scene, camera } = mindarThree

        target.posters.forEach((poster, index) => {
          const video = new VideoTexture(poster.vid)

          const anchor = mindarThree.addAnchor(index)
          anchor.onTargetFound = () => {
            poster.vid.hidden = false
            poster.vid.play()
            setIsPlaying(true)
            setIsMuted(true)
            setFocusedPoster(poster)
          }
          anchor.onTargetLost = () => {
            poster.vid.pause()
            setIsPlaying(false)
            setIsMuted(true)
            setFocusedPoster(undefined)
          }

          // fixTexture makes sure the video scales to fix aspect ratio
          function fixTexture(texture: VideoTexture, planeWidth: number, planeHeight: number) {
            const aspect = poster.img.height / poster.img.width

            texture.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0
            texture.repeat.x = aspect > 1 ? 1 / aspect : 1

            texture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2
            texture.repeat.y = aspect > 1 ? 1 : aspect
          }

          fixTexture(video, poster.img.width, poster.img.height)

          const geometry = new PlaneGeometry(1, poster.img.height / poster.img.width)

          const material = new MeshBasicMaterial({
            transparent: false,
            opacity: 1,
            map: video,
          })

          const plane = new Mesh(geometry, material)
          anchor.group.add(plane)

          var logogeometry = new PlaneGeometry(0.2, 0.2)
          var Logotexture = new TextureLoader().load(LogoImage)
          var logoMaterial = new MeshBasicMaterial({ map: Logotexture })
          var logoMesh = new Mesh(logogeometry, logoMaterial)
          logoMesh.position.setZ(-0.1)
          anchor.group.add(logoMesh)
        })

        const start = async () => {
          await mindarThree.start()
          renderer.setAnimationLoop(() => {
            renderer.render(scene, camera)
          })
        }

        start()
      })
    }
  }, [sceneRef, stickerID, posterID])

  function PlayVideo() {
    if (focusedPoster === undefined) return
    isPlaying ? focusedPoster.vid.pause() : focusedPoster.vid.play()
    setIsPlaying(!isPlaying)
  }

  function MuteVideo() {
    if (focusedPoster === undefined) return
    if (isMuted) {
      focusedPoster.vid.muted = false
    } else {
      focusedPoster.vid.muted = true
    }
    setIsMuted(!isMuted)
  }

  return (
    <div className={styles.root} style={{ height: window.innerHeight }}>
      <div className={styles.containerUI} style={{ height: window.innerHeight }} onClick={PlayVideo}>
        <LoadingPreview title={status} />
        <TopAppButton />
        <AppButton />
        <MapButton />
        <LoadingAssetsView configuration={compilation} progress={progress} />
        <MainMenuContainer
          focusedPoster={focusedPoster}
          onPlay={PlayVideo}
          isPlaying={isPlaying}
          isMuted={isMuted}
          onMuted={MuteVideo}
        />
      </div>

      <div className={styles.container} id="container" ref={sceneRef} style={{ height: window.innerHeight }}></div>

      {imageUrl !== undefined && focusedPoster === undefined && (
        <div className={styles.containerPreviewImage} style={{ height: window.innerHeight }}>
          <div className={styles.PreviewImage} style={{ backgroundImage: "url(" + imageUrl + ")" }}></div>
        </div>
      )}
    </div>
  )
}

export default Main
